import React, {createContext, Dispatch, ReactNode, useReducer} from "react";

interface IMenuContextState {
    active: MenuItem;
}

type MenuItem = "home" | "tools" | "contact";
type IMenuContextActions = {type: 'ACTIVE', data: MenuItem};

const menuReducer = (state: IMenuContextState, action: IMenuContextActions) => {
    switch (action.type) {
        case "ACTIVE":
            return {
                active: action.data
            };
        default:
            return state;
    }
};

interface IMenuContext extends IMenuContextState{
    setActive: (menuItem: MenuItem) => void;
}

export const MenuContext = createContext<IMenuContext | undefined>(undefined);

export const MenuContextProvider = ({children}: {children: ReactNode}) => {
    const [state, dispatch] = useReducer(menuReducer, {active: "home"});

    const value: IMenuContext = {
        ...state,
        setActive: (menuItem) => dispatch({type: "ACTIVE", data: menuItem})
    }

    return (
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    )
}
