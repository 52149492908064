import P5 from "p5";


let padding;
const speed = 0.0005;
let elements = [];
let isOver = false;
let angle = 0;
let xw;
let xh;

export const sketch = (p5: P5, width: number, height: number) => {

    p5.setup = () => {
        let canvas = p5.createCanvas(width, height);
        canvas.mouseOver(() => isOver = true);
        canvas.mouseOut(() => isOver = false);
        p5.pixelDensity(1);
        p5.angleMode(p5.DEGREES);
        p5.rectMode(p5.CENTER);

        update(width, height);
    };

    p5.draw = () => {
        p5.background(p5.mouseIsPressed && isOver ? 255 : 0);
        p5.stroke(p5.mouseIsPressed && isOver ? 0 : 255)
        for (var i = 0; i < elements.length; i++) {
            elements[i].rotate();
        }
    };

    const update = (width, height) => {
        elements = [];
        xw = width/6;
        xh = height/6;
        padding = width * 0.05;
        for (var xc = width/8; xc <= width - width/8; xc+= padding) {
            for (var yc = width/8; yc <= height - width/8; yc+= padding) {
                const m = new Element(p5.int(xc), p5.int(yc), p5, width);
                elements.push(m);
            }
        }
    }

    return update;
};

function Element(x, y, p5, width) {
    this.x = x;
    this.y = y;
    this.w = 0.1;
    this.h = width/20;
    this.p5 = p5;
}

Element.prototype.rotate = function() {

    this.p5.push();
    this.p5.translate(this.x, this.y);
    angle += speed;

    if(isOver && this.p5.mouseX && this.p5.mouseY){
        let a = this.p5.atan2(this.p5.mouseY - this.y, this.p5.mouseX - this.x) + 90;
        this.p5.rotate(a);
    }else{
        this.p5.rotate(angle + (this.x + this.y) * 0.1);
    }
    this.p5.rect(0, 0, this.w, this.h);
    this.p5.pop();
}
