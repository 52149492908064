import React, {useContext, useEffect, useRef, useState} from "react";
import {MenuContext} from "./context/menu";
import "./app.scss";
import Sketch from "./sketch";
import P5 from "p5";
import {sketch as s} from "./circle";
import {sketch as s2} from "./clouds";
import axios from "axios";


const App = () => {
    const homeRef = useRef(null);

    return (
        <>
            {/*<header>*/}
            {/*    <Menu/>*/}
            {/*</header>*/}
            <main>
                <Home/>
                <Tools/>
                <Instagram/>
                <Contact/>
            </main>
        </>
    );
};

const Home = () => {
    return (
        <section className={"home"}>
            <Hello/>
            <Sketch value={s} className={"sketch"}/>
        </section>
    );
}

const Hello = () => {
    return (
        <div className={"hello"} id={"hello"}>
            <p>Hi! I'm José and I like to build stuff for the web. Originally from Portugal but currently based in
                London. I'm into fullstack development and creative coding. Currently working <strong><a href={"https://www.premierleague.com/"} target={"_blank"}>@PremierLeague</a></strong>.
            </p>
        </div>
    );
}

const Tools = () => {
    return (
        <section className={"tools"} id={"tools"}>

            <div className={"description"}>
                Have been working as a fullstack developer focusing on single page applications. Occasionally play with p5.js and three.js.
            </div>
            <Sketch value={s2} className={"sketch"}>
                <div className={"skills"}>
                    <h1>React</h1>
                    <h2>Typescript</h2>
                    <h3>CSS & HTML</h3>
                    <h4>.Net Core</h4>
                    <h5>T-SQL</h5>
                    <h6>C#</h6>
                </div>
            </Sketch>
        </section>
    );
}

const Instagram = () => {
    return (
            <InstagramPhotos/>
    );
}

const Contact = () => {
    return (
        <section className={"contact"} id={"contact"}>
            {/*<div className={"skills"}>*/}
            {/*    <h1>React</h1>*/}
            {/*    <h2>Typescript</h2>*/}
            {/*    <h3>CSS & HTML</h3>*/}
            {/*    <h4>.Net Core</h4>*/}
            {/*    <h5>T-SQL</h5>*/}
            {/*    <h6>C#</h6>*/}
            {/*</div>*/}
            {/*<div className={"description"}>*/}
            {/*    Have been working as a fullstack but done various other projects like mobile development (Cordova), desktop (Electron), excel (Office.js), etc. I enjoy to try new things and I definetely learned from that but my expertise it's within the frontend scope. Occasionally on my free time I play with p5.js and three.js. I'm a fan of creative coding and generative art.*/}
            {/*</div>*/}
            {/*<InstagramPhotos/>*/}

            <div className={"enquiries"}>
                <h5>Enquiries</h5>
                <h1><a href={"mailto:jjtfsalgado@gmail.com"}>jjtfsalgado@gmail.com</a></h1>
            </div>

            <div className={"social"}>
                <h5>Social</h5>
                <div className={"links"}>
                    <h1><a href="https://www.linkedin.com/in/jos%C3%A9-salgado-7a1236116/" target={"_blank"}>Linkedin</a></h1>
                    <h1><a href="https://github.com/jjtfsalgado" target={"_blank"}>Github</a></h1>
                    <h1><a href="https://codepen.io/jjsalgado" target={"_blank"}>Codepen</a></h1>
                    <h1><a href="https://www.instagram.com/ze.salgado/" target={"_blank"}>Instagram</a></h1>
                </div>

            </div>
        </section>
    );
}

const EmailIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"/>
    </svg>;
}

const InstagramIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
    </svg>;
}

const LinkedinIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
    </svg>
}

const GithubIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
    </svg>;
}

const CodepenIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1"
                width="24" height="24" viewBox="0 0 24 24">
<g>
	<path
        d="M16.878,0.415c-0.854-0.565-1.968-0.552-2.809,0.034L1.485,9.214c-0.671,0.468-1.071,1.233-1.071,2.052v9.444   c0,0.84,0.421,1.623,1.122,2.086l12.79,8.455c0.836,0.553,1.922,0.553,2.758,0l13.044-8.618c0.7-0.463,1.122-1.246,1.122-2.086   v-9.279c0-0.839-0.421-1.622-1.121-2.085L16.878,0.415z M26.621,10.645l-4.821,3.237l-4.521-3.288L17.25,4.127L26.621,10.645z    M13.979,4.133v6.329l-4.633,3.24l-4.621-3.099L13.979,4.133z M3.458,13.722l2.991,2.004l-2.991,2.093V13.722z M14.058,27.215   l-9.331-6.258l4.661-3.258l4.67,3.133V27.215z M12.286,15.674l3.021-2.113l3.519,2.313l-3.119,2.095L12.286,15.674z M17.354,27.215   V20.83l4.463-2.991l4.805,3.159L17.354,27.215z M27.954,17.927l-3.168-2.082l3.168-2.125V17.927z"/>
</g>

</svg>;
}

const Menu = () => {
    const productCtx = useContext(MenuContext);
    const active = productCtx?.active;

    return (
        <div className={"menu"}>
            <ul>
                <li className={active == "home" ? "active" : null} onClick={() => productCtx.setActive("home")}><a href={"#hello"}>Hi</a></li>
                <li className={active == "tools" ? "active" : null}
                    onClick={() => productCtx.setActive("tools")}><a href={"#tools"}>Tools</a>
                </li>
                <li className={active == "contact" ? "active" : null}
                    onClick={() => productCtx.setActive("contact")}><a href={"#contact"}>Hi</a>
                </li>
            </ul>
        </div>
    )
}

const InstagramPhotos = () => {
    const [state, setState] = useState<Array<any>>([]);
    const [visible, setVisible] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            try{
                const posts = await getInstagramPosts()
                setState(posts);
            }catch (e){
                setVisible(false)
            }
        })()
    }, []);

    if(!visible) return null;

    return (
        <section className={"instagram"}>
            <div className={"photos"}>
                {state.map(i => <img onClick={() => window.open(i.permalink, "_blank")} src={i.media_url} key={i.id}/>)}
            </div>
        </section>
    )
}

function onMouseMove(e, ref) {
    const target = e.target;
    if (!target) return;
    // window.requestAnimationFrame(function(){
    //     target.style.transform = transforms(e.clientX, e.clientY, target);
    // });
}

function transforms(x, y, el) {
    let box = el.getBoundingClientRect();
    let calcX = -(y - box.y - (box.height / 2)) / (100);
    let calcY = (x - box.x - (box.width / 2)) / (100);

    return "perspective(100px) "
        + "   rotateX(" + calcX + "deg) "
        + "   rotateY(" + calcY + "deg) ";
};


const INSTA_TOKEN = "IGQVJYWkU0N1dJSk52V0pocHdxbEwweTVLLUVlLXBtVTcwbVZAtOXBLaENRQ2VCUDhBYzdtVV9HbGI4OVRVMVpRTXpSNUtCVXNmVERUa1VTWldvNEZAqbTlxZAkJWVlZARRlA5V2ZARckpmblV5Q1d2OWxNSUsxUjUtMjZAwUWNz";
const INSTA_USER_ID = "17841401338111885";

async function getInstagramPosts() {
    return await axios.get(`https://graph.instagram.com/${INSTA_USER_ID}/media?fields=id,media_url,permalink,username,timestamp&access_token=${INSTA_TOKEN}`).then(res => res.data.data.slice(0, 10));
}

export default App;
