import P5 from "p5";

const speed = 0.0001;
let pg;
let inc = 0;
let scl = 0.015;
let yoff = 0;
let xoff = 0;
let zoff = 0;
let isOver = false;


export const sketch = (p5: P5, width, height) => {

    p5.setup = () => {
        let canvas = p5.createCanvas(width, height);
        canvas.mouseOver(() => isOver = true);
        canvas.mouseOut(() => isOver = false);
        p5.pixelDensity(1);
        p5.background(255, 204, 0);
        p5.translate(width / 2, height / 2);
    };

    p5.draw = () => {
        update(width, height);
    };

    const update = (width, height) => {
        yoff = inc - (p5.noise(inc) * p5.mouseY) / 1000;
        const cy = height / 2;
        const cx = width / 2;

        for (let y = 0; y < height; y += 10) {
            xoff = inc - (p5.noise(inc) * p5.mouseX) / 1000;
            for (let x = 0; x < width; x += 10) {
                const c = p5.map(p5.noise(xoff, yoff, zoff), 0, 1, 0.6, 1.2);

                p5.noStroke();
                p5.fill(255 * c, 204 * c, 0);
                p5.square(x, y, 10);
                xoff += scl;
            }

            yoff += scl;
        }

        zoff += scl;
    }

    return update;
};
