import p5 from "p5";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import ResizeObserver from 'resize-observer-polyfill'

interface ISketchProps {
    value: (sketch: p5State, width: number, height: number) => void;
    className?: string;
    children?: ReactNode;
}

export type p5State = {state: {width: number, height: number}} & p5;


const Sketch = <T extends {}>(props: ISketchProps) => {
    const [sketch, setSketch] = useState<p5State>();
    const ref = useRef(null);
    const {className} = props;
    let update;


    useEffect(() => {
        const dim = getDimensions(ref);
        const s = new p5((s) => {
            update = props.value(s, dim.width, dim.height)
        }, ref.current as any) as p5State;
        setSketch(s);

        const resizeObserver = new ResizeObserver(entries => {
            const dim2 = getDimensions(ref);
            s.resizeCanvas(dim2.width, dim2.height);
            update && update(dim2.width, dim2.height);
        });

        resizeObserver.observe(ref.current)

        return () => s.remove();
    }, []);

    return <div style={{position: "relative"}} ref={ref} className={className}>{props.children}</div>;
};

function getDimensions(ref: React.RefObject<any>){
    return {width: (ref?.current as any)?.offsetWidth || 0, height: (ref?.current as any)?.offsetHeight || 0};
}

export default Sketch;
