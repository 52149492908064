
import "./styles.scss";
import React from "react";
import ReactDOM from "react-dom";
import Sketch from "./sketch";
import { MenuContextProvider } from "./context/menu";
import App from "./app";

export const _DEV_ = process.env.NODE_ENV === "development";

ReactDOM.render(
    <MenuContextProvider>
        <App/>
    </MenuContextProvider>,
    document.getElementById('app')
);
